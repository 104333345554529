<script setup>
import { __ } from 'JIX/gettext.js';
import { ref, computed, defineAsyncComponent, onMounted } from 'vue';

import useTimeOfDay from 'JIX/timeOfDay.js';
import { EditSections, EditSectionFragments } from 'JIX/cv/edit/sections.js';
import { LoginResult, checkLogin } from 'JIX/utils/login.js';

import DsAddInline from 'JIX/components/ds/DsAddInline.vue';
import DsIconButton from 'JIX/components/ds/DsIconButton.vue';
import SvgIcon from 'JIX/components/SvgIcon.vue';

import CvOnlineSelect from './CvOnlineSelect.vue';
import UserDashboardCard from './UserDashboardCard.vue';

const DsFeatureTip = defineAsyncComponent(() => import('JIX/components/ds/DsFeatureTip.vue'));

import { userSilhuette } from 'JIX/utils/paths.js';
import { api } from 'JIX/utils.js';

import i18n from './UserDashboard.i18n.js';

import 'icons/close.svg';
import 'icons/color-tip.svg';
import 'icons/button-edit.svg';
import 'icons/myjob-unsaved.svg';
import 'icons/service-cv.svg';
import 'icons/service-jobsearch.svg';

const props = defineProps({
    user: {
        type: Object,
        required: false,
        default: null,
    },
    cv: {
        type: Object,
        required: false,
        default: null,
    },
    latestJob: {
        type: Object,
        required: false,
        default: null,
    },
    primaryEducation: {
        type: Object,
        required: false,
        default: null,
    },
    myjobs: {
        type: Object,
        required: false,
        default: null,
    },
    latestJobsearch: {
        type: Object,
        required: false,
        default: null,
    },
    urls: {
        type: Object,
        required: true,
    },
    dismissCategories: {
        type: Object,
        required: true,
    },
    dismissStatus: {
        type: Object,
        required: true,
    },
});

const csrfToken = window.Stash.common.csrf_token;

const timeOfDay = useTimeOfDay();

const latestJobFormatted = computed(() => {
    if (!props.latestJob) {
        return null;
    }
    return [props.latestJob.name, props.latestJob.company].filter(x => !!x).join(', ');
});

const cvJobHistoryUrl = computed(() => props.urls.cv_edit_edit + '#' + EditSectionFragments[EditSections.JOBHISTORY]);
const cvEducationHistoryUrl = computed(() => props.urls.cv_edit_edit + '#' + EditSectionFragments[EditSections.EDUHISTORY]);

const photo = computed(() => props.user?.photo || userSilhuette());

const cvOnlineState = ref(props.cv?.online ?? false);

const cvCreate = ref();
const cvOnlineSelect = ref();
const cvOnlineTip = ref();

const showCvOnlineTip = ref(false);
const showCvEducationTip = ref(false);
const showCvCreateCard = ref(!props.cv && !props.dismissStatus.cvCreateCard);

function dismissCvCreateCard() {
    showCvCreateCard.value = false;
    return dismissTip(props.dismissCategories.cvCreateCard);
}

onMounted(() => {
    showCvOnlineTip.value = !props.dismissStatus.cvOnline && props.cv && !cvOnlineState.value;
    showCvEducationTip.value = !showCvOnlineTip.value && props.cv && !props.dismissStatus.cvEducation && !props.primaryEducation;
});

async function setCvOnline(online, analyticsEvent = true) {
    if (online === cvOnlineState.value) {
        return;
    }
    const payload = { activate: online };
    const res = await api('PATCH', '/api/cv/v1', payload, { json: true });
    cvOnlineState.value = res.isOnline;
    if (analyticsEvent) {
        analytics('toggle-cv-online', online.toString());
    }
}

async function navigateLogin(url) {
    const res = await checkLogin();
    if (res !== LoginResult.ABORTED) {
        location.href = url;
    }
}

async function handleCreateCvForm(event) {
    event.preventDefault();
    const res = await checkLogin({ redirect: props.urls.cv_edit_create + '?source=user-dashboard' });
    if (res !== LoginResult.ABORTED) {
        analytics('click', 'create-cv');
        event.target.submit();
    }
}

async function dismissTip(category) {
    return api('PUT', '/api/user/dismiss/v1', { category }, { json: true });
}

function analytics(action, label) {
    window.jixAnalytics('event', { category: 'user-dashboard', action, label });
}

const cardMyjobs = ref();
const cardJobsearch = ref();
const cardCvstats = ref();
</script>

<template>
    <div class="user-dashboard">
        <div class="user-dashboard__photo-and-details">
            <img
                :src="photo"
                class="user-dashboard__photo avatar"
                :class="'avatar--lg'"
                alt=""
            >
            <div class="user-dashboard__details">
                <h3 class="user-dashboard__greeting">
                    {{ i18n.greeting(user?.firstname, timeOfDay) }}
                </h3>
                <div class="user-dashboard__latest-job" v-if="latestJobFormatted">
                    {{ latestJobFormatted }}
                    <div class="btn-wrapper-y">
                        <DsIconButton
                            icon-name="button-edit"
                            type="secondary"
                            size="md"
                            :label="i18n.editJobHistory()"
                            :href="cvJobHistoryUrl"
                            @click="analytics('click', 'edit-latest-job')"
                        />
                    </div>
                </div>
                <DsAddInline
                    :href="cvJobHistoryUrl"
                    @click="analytics('click', 'add-latest-job')"
                    v-else-if="cv"
                >
                    {{ i18n.addLatestJob() }}
                </DsAddInline>
                <form
                    :action="urls.cv_edit_create"
                    method="post"
                    class="d-inline-block"
                    @submit="handleCreateCvForm"
                    v-else-if="dismissStatus.cvCreateCard"
                >
                    <input type="hidden" name="csrf_token" :value="csrfToken">
                    <input type="hidden" name="source" value="user-dashboard">
                    <DsAddInline
                        type="submit"
                        ref="cvCreate"
                    >
                        {{ i18n.createCv() }}
                    </DsAddInline>
                </form>

                <CvOnlineSelect
                    :online="cvOnlineState"
                    @update:online="setCvOnline"
                    ref="cvOnlineSelect"
                    v-if="cv"
                />
            </div>
        </div>

        <div class="user-dashboard__cards">
            <UserDashboardCard
                icon-name="myjob-unsaved"
                :href="urls.myjob_overview"
                :warning="myjobs && myjobs.notApplied > 0"
                @click.prevent="navigateLogin(urls.myjob_overview); analytics('click', 'myjob-card')"
                ref="cardMyjobs"
            >
                {{ user && myjobs ? i18n.numSavedJobs(myjobs?.active ?? 0) : i18n.savedJobs() }}
                <template #description>
                    {{ user && myjobs ? i18n.numSavedJobsNotApplied(myjobs.notApplied) : i18n.myjobPlaceholder() }}
                </template>
            </UserDashboardCard>
            <UserDashboardCard
                icon-name="service-jobsearch"
                :href="latestJobsearch.url"
                v-if="latestJobsearch"
                @click="analytics('click', 'latest-jobsearch-card')"
                ref="cardJobsearch"
            >
                <span class="d-inline-flex gap-2 align-items-center">
                    {{ i18n.jobHitcount(latestJobsearch.hitcount) }}
                    <span class="inline-notification" v-if="latestJobsearch.hitcountNew > 0">{{ i18n.numNew(latestJobsearch.hitcountNew) }}</span>
                </span>
                <template #description>
                    {{ latestJobsearch.name }}
                </template>
            </UserDashboardCard>
            <UserDashboardCard
                icon-name="service-cv"
                :href="urls.cv_statistics_root"
                v-if="cv?.stats"
                @click="analytics('click', 'cvstat-card')"
                ref="cardCvstats"
            >
                {{ i18n.numExposures(cv.stats.month_exposures) }}
                <template #description>
                    {{ i18n.cvStatsDesc(30) }}
                </template>
            </UserDashboardCard>
            <form
                :action="urls.cv_edit_create"
                method="post"
                class="p-3 bg-surface-default border"
                v-if="showCvCreateCard"
                ref="cvCreate"
                @submit="handleCreateCvForm"
            >
                <input type="hidden" name="csrf_token" :value="csrfToken">
                <input type="hidden" name="source" value="user-dashboard">
                <div class="d-flex flex-row justify-content-between w-100 mb-3">
                    <div>
                        <p class="font-weight-bold mb-0">
                            {{ __('Vores bedste tip til din') }}
                            <span class="d-inline-flex align-items-center">
                                {{ __('jobsøgning') }}
                                <SvgIcon
                                    name="color-tip"
                                    size="xs"
                                    role="presentation"
                                    class="ml-1"
                                />
                            </span>
                        </p>
                        <p>{{ i18n.cvCreateTipLine2() }}</p>
                    </div>
                    <DsIconButton
                        icon-name="close"
                        type="secondary"
                        size="sm"
                        :label="i18n.close()"
                        @click.prevent="dismissCvCreateCard"
                        v-if="user"
                    />
                </div>
                <button
                    type="submit"
                    class="btn btn-primary btn-sm btn-block"
                >
                    {{ i18n.createCv() }}
                </button>
            </form>
        </div>
        <DsFeatureTip
            :header="i18n.cvOnlineTipHeader()"
            :header-tag="i18n.cvOnlineTipHeaderTag()"
            position="bottom"
            :target="cvOnlineSelect"
            :button="{ label: i18n.setCvOnline() }"
            @button-click="setCvOnline(true, false); analytics('click', 'cv-online-tip'); $refs.cvOnlineTip.hide()"
            @hiding="dismissTip(dismissCategories.cvOnline)"
            @hidden="showCvOnlineTip = false"
            ref="cvOnlineTip"
            :auto-show="true"
            v-if="showCvOnlineTip"
        >
            {{ i18n.cvOnlineTipBody() }}
        </DsFeatureTip>
        <DsFeatureTip
            :header="i18n.cvEducationTipHeader()"
            :header-tag="i18n.cvEducationTipHeaderTag()"
            position="bottom"
            :target="cvOnlineSelect"
            :button="{ label: i18n.addEducation(), href: cvEducationHistoryUrl }"
            @button-click="analytics('click', 'cv-education-tip')"
            @hiding="dismissTip(dismissCategories.cvEducation)"
            @hidden="showCvEducationTip = false"
            ref="cvEducationTip"
            :auto-show="true"
            v-if="showCvEducationTip"
        >
            {{ i18n.cvEducationTipBody() }}
        </DsFeatureTip>
    </div>
</template>
